import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector, Select } from '@ngxs/store';
import { AgreeOnTermsOfService, DisagreeOnTermsOfService } from '../actions/discoreco.action';
import { DiscorecoService } from '../services/discoreco/discoreco.service';
// import 'rxjs/add/operator/toPromise';

export class DiscorecoStateModel {
  termsOfServiceAgreed: boolean;
}

@Injectable()
@State<DiscorecoStateModel>({
  name: 'discoreco',
  defaults: {
    termsOfServiceAgreed: undefined
  }
})
@Injectable() // don't know why
export class DiscorecoState {
  constructor(private discorecoService: DiscorecoService) {}

  @Selector()
  static termsOfServiceAgreed(state: DiscorecoStateModel) {
    return state.termsOfServiceAgreed;
  }

  @Action(AgreeOnTermsOfService)
  async agreeOnTermsOfService(ctx: StateContext<DiscorecoStateModel>, org) {

    const res = await this.discorecoService.agreeOnTermsOfservice(org.org);

    // // console.log(2, orgsArray)
    // ctx.patchState({
    //   organizations: Object.fromEntries(orgsArray.map(o => [o['name'], o]))
    // })

    ctx.patchState({
      termsOfServiceAgreed: true
    }) //.toPromise()
  }

  @Action(DisagreeOnTermsOfService)
  async disagreeOnTermsOfService(ctx: StateContext<DiscorecoStateModel>) {
    ctx.patchState({
      termsOfServiceAgreed: false
    })
  }
}
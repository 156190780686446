import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector, Select, createSelector } from '@ngxs/store';
import { Organization } from '../models/organization';
import { OrganizationService } from '../services/organization/organization.service';
import {
    CreateOrganization,
    UpdateOrganization,
    GetAllOrganizations,
    GetOrganization,
    SetCurrentOrganization,
    CreateGroup,
    UpdateGroup,
    DeleteGroup,
    GetOrgGroups,
    RemoveGroupMembers,
    GetGroupMembers,
    AddGroupMembers,
    SetCurrentOrganizationService
} from '../actions/organization.action';
import { ServiceState, ServiceStateModel } from "./service.state";

export class OrganizationStateModel {
    organizations: {
        [orgName: string]: Organization
    }
    currentOrg: string
    currentService: string
}

@Injectable()
@State<OrganizationStateModel>({
    name: 'organization',
    defaults: {
        currentOrg: undefined,
        currentService: undefined,
        organizations: {}
    }
})

export class OrganizationState {
    constructor(private organizationService: OrganizationService) {
    }

    // Current organisation's detail
    @Selector()
    static organization(state: OrganizationStateModel) {
        return state.organizations[state.currentOrg];
    }

    // current orgniazation's name
    @Selector()
    static currentOrg(state: OrganizationStateModel) {
        // console.log(state)
        return state.currentOrg;
    }

    // current orgniazation's name
    @Selector()
    static currentService(state: OrganizationStateModel) {
        return state.currentService;
    }

    // All the organizations
    @Selector()
    static organizations(state: OrganizationStateModel) {
        return Object.values(state.organizations)
    }

    // All the groups of current organisation
    @Selector()
    static groups(state: OrganizationStateModel) {
        return Object.values(state.organizations[state.currentOrg].groups);
    }

    // Get the services/actions that belong to the current organisation
    @Selector([ServiceState])
    static services(state: OrganizationStateModel, serviceState: ServiceStateModel) {
        let serviceActionMap = new Map<string, Array<string>>();

        serviceState.services.forEach(s => {
            if (!serviceActionMap.has(s.name)) {
                serviceActionMap.set(s.name, [s.action])
            } else {
                serviceActionMap.get(s.name).push(s.action)
            }
        });

        const result = state.organizations[state.currentOrg].services.map(s => {
            return {
                "service": s.name,
                "actions": serviceActionMap.get(s.name),
                "objects": s.objects
            }
        });
        return result;
    }

    @Action(GetOrganization)
    async getOrganization(ctx: StateContext<OrganizationStateModel>, org: GetOrganization) {
        const orgDetail = await this.organizationService.getOrganization(org.name);
        let orgs = { ...ctx.getState().organizations };

        // console.log(1, orgs, orgDetail)

        orgs[org.name] = orgDetail
        ctx.patchState({
            organizations: orgs
        })
    }

    @Action(SetCurrentOrganization)
    setCurrentOrganization(ctx: StateContext<OrganizationStateModel>, org: GetOrganization) {
        // console.log(org.name)
        ctx.patchState({
            currentOrg: org.name
        })
    }

    @Action(SetCurrentOrganizationService)
    setCurrentOrganizationService(ctx: StateContext<OrganizationStateModel>, service: SetCurrentOrganizationService) {
        // console.log(org.name)
        ctx.patchState({
            currentService: service.service
        })
    }

    @Action(GetAllOrganizations)
    async getAllOrganizations(ctx: StateContext<OrganizationStateModel>) {
        const orgsArray = await this.organizationService.getAllOrganizations();

        // console.log(2, orgsArray)
        ctx.patchState({
            organizations: Object.fromEntries(orgsArray.map(o => [o['name'], o]))
        })
    }

    @Action(CreateOrganization)
    async createOrganization(ctx: StateContext<OrganizationStateModel>, org: CreateOrganization) {
        await this.organizationService.createOrganization(org.payload);
    }

    @Action(UpdateOrganization)
    async updateOrganization(ctx: StateContext<OrganizationStateModel>, org: UpdateOrganization) {
        await this.organizationService.updateOrganization(org.payload);
    }

    @Action(CreateGroup)
    async createGroup(ctx: StateContext<OrganizationStateModel>, action: CreateGroup) {
        await this.organizationService.createGroup(action.orgName, action.groupName, action.policy);
    }

    @Action(UpdateGroup)
    async updateGroup(ctx: StateContext<OrganizationStateModel>, action: UpdateGroup) {
        await this.organizationService.updateGroup(action.orgName, action.groupName, action.policy);
    }

    @Action(DeleteGroup)
    async deleteGroup(ctx: StateContext<OrganizationStateModel>, action: DeleteGroup) {
        await this.organizationService.removeGroup(action.orgName, action.groupName);
    }

    @Action(GetOrgGroups)
    async getOrgGroups(ctx: StateContext<OrganizationStateModel>, action: GetOrgGroups) {
        let groups = await this.organizationService.getGroups(action.orgName);
        let orgs = { ...ctx.getState().organizations };

        // console.log(orgs, action.orgName, groups)
        orgs[action.orgName]['groups'] = groups

        ctx.patchState({
            organizations: orgs
        })

    }

    @Action(AddGroupMembers)
    async addGroupMembers(ctx: StateContext<OrganizationStateModel>, action: AddGroupMembers) {
        await this.organizationService.addGroupMembers(action.orgName, action.groupName, action.users);
    }

    @Action(RemoveGroupMembers)
    async removeGroupMember(ctx: StateContext<OrganizationStateModel>, action: RemoveGroupMembers) {
        await this.organizationService.removeGroupMember(action.orgName, action.groupName, action.users);
    }

    @Action(GetGroupMembers)
    async getGroupMembers(ctx: StateContext<OrganizationStateModel>, action: GetGroupMembers) {
        const users = await this.organizationService.getGroupMembers(action.orgName, action.groupName);
        let orgs = { ...ctx.getState().organizations };

        const index = orgs[action.orgName]['groups'].findIndex(group => group.name == action.groupName);
        orgs[action.orgName]['groups'][index]['users'] = users;

        ctx.patchState({
            organizations: orgs
        })
    }
}
import { Component, OnInit } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/store/models/user';
import { UserState } from 'src/app/store/states/user.state';

@Component({
  selector: 'app-baseuser',
  templateUrl: './baseuser.component.html',
  styleUrls: ['./baseuser.component.scss'],
})
export class BaseuserComponent implements OnInit {

  // way one will always got an empty user cuz object mapping
  // @SelectSnapshot(UserState.user) user: User;
  // way two
  @Select(UserState.user) user$: Observable<User>;
  user: User = null;

  constructor() {
    this.user$.subscribe(user => {
      if (user.id) this.user = user
    });
  }

  ngOnInit() { }

}

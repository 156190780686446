import { Injectable } from '@angular/core';
import { IxHttp } from 'src/app/utils/ix-http';
import { ApiService } from '../api/api.service';

// const API_NAME = 'influxapi';
@Injectable({
  providedIn: 'root'
})
export class PolicyService extends ApiService {

  constructor(private ixHttp: IxHttp) {
    super()
  }

  async addOrgUser(payload) {
    let init = this.getAPIInit();
    init["body"] = payload
    const params = { 'body': payload }
    // return await API.post(API_NAME, "/policy", init);
    return await this.ixHttp.post( "/policy", params);
  }

  async updateOrgUser(payload) {
    let init = this.getAPIInit();
    init["body"] = payload
    const params = { 'body': payload }
    // return await API.put(API_NAME, "/policy", init);
    return await this.ixHttp.put( "/policy", params);
  }
}

import { Injectable } from '@angular/core';
import { IxHttp } from 'src/app/utils/ix-http';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  // API_NAME = "influxapi"

  constructor(private ixHttp: IxHttp) {
    super()
  }

  // sleep = ms => new Promise(r => setTimeout(r, ms));

  async getUser() {
    // await this.sleep(5000);
    // const init = this.getAPIInit();
    // return await API.get(this.API_NAME, "/user", init);
    return await this.ixHttp.get("/user");
  }

  async getOrgUsers(orgName) {
    const init = this.getAPIInit();
    // return await API.get(this.API_NAME, "/user/org/" + orgName, init);
    return await this.ixHttp.get("/user/org/" + orgName);
  }

  async deleteOrgUsers(orgName: string, users: string[]) {
    const init = this.getAPIInit();
    init['body'] = { users };
    const params = { 'body': { users } }
    // return await API.del(this.API_NAME, "/user/org/" + orgName, init);
    return await this.ixHttp.delete("/user/org/" + orgName, params);
  }
}
import { Injectable } from '@angular/core';
import Amplify from 'aws-amplify';
import awsconfig from '../../../../aws-exports';
import { ApiService } from '../api/api.service';
import { DiscorecoRequest } from '../../models/discoreco';
import { IxHttp } from 'src/app/utils/ix-http';

Amplify.configure(awsconfig);

@Injectable({
  providedIn: 'root'
})
export class DiscorecoService extends ApiService {
  // apiName = 'influxapi';
  path = '/discoreco';
  batchSize = 20;
  tab_name = 'single-discoreco'
  tab_icp = ''

  constructor(private ixHttp: IxHttp) {
    super();
  }

  async createTask(org: string,
    requestBody: DiscorecoRequest) {
    const init = this.getAPIInit();
    const path = `${this.path}/organisation/${org}/task/create`;
    const myInit = {
      ...init,
      body: requestBody
    };
    const params = { 'body': requestBody }
    // return await API.put(this.apiName, path, myInit);
    return await this.ixHttp.put(path, params);
  }

  async validate(payload) {
    let init = this.getAPIInit();
    init["body"] = payload;
    const params = { 'body': payload }

    // return API.put(this.apiName, this.path + '/validate', init);
    return await this.ixHttp.put('/validate', params);
  }

  async getMeters(org: string, icp: string) {
    const init = this.getAPIInit();
    const path = `${this.path}/organisation/${org}/icp/${icp}/meters`;
    // return await API.get(this.apiName, path, init);
    return await this.ixHttp.get(path);
  }

  async getMetersByICPs(org: string, icps: string[]) {
    const init = this.getAPIInit();
    const params = { 'body': { 'icps': icps } }
    const path = `${this.path}/organisation/${org}/meters`;
    // return await API.get(this.apiName, path, init);
    return await this.ixHttp.post(path, params);
  }

  async getTermsOfService(org) {
    const init = this.getAPIInit();
    const path = `${this.path}/organisation/${org}/termsOfService`;
    // return await API.get(this.apiName, path, init);
    return await this.ixHttp.get(path);
  }

  async agreeOnTermsOfservice(org) {
    let init = this.getAPIInit();
    const path = `${this.path}/organisation/${org}/termsOfService/agree`;
    // return await API.get(this.apiName, path, init);
    return await this.ixHttp.put(path);
  }

  async getTasks(org: string, startKey = undefined) {
    const init = this.getAPIInit();

    let path = `${this.path}/organisation/${org}/task?limit=${this.batchSize}`;

    if (startKey) {
      path += `&startKey=${startKey}`;
    }
    // return await API.get(this.apiName, path, init);
    return await this.ixHttp.get(path);
  }

  async getHistoricalTasks(org, from, to, startKey = undefined, progress = 1) {
    const init = this.getAPIInit();
    let path = `${this.path}/organisation/${org}/history?from=${from}&to=${to}`;

    if (startKey) {
      path += `&startKey=${startKey}`;
    }

    // return await API.get(this.apiName, path, init);
    return await this.ixHttp.get(path, {}, progress);
  }

  async verifyTask(taskId: string, pass: boolean) {
    const path = `${this.path}/task/${taskId}/verify`;

    let init = this.getAPIInit();
    init["body"] = { pass: pass };
    const params = { 'body': { pass: pass } }

    // return API.put(this.apiName, path, init);
    return this.ixHttp.put(path, params);
  }

  async getRetailerCodes(org) {
    const path = `${this.path}/organisation/${org}/retailer/codes`;
    // const path = `${this.path}/retailer/codes`;
    const params = { }
    // return API.put(this.apiName, path, init);
    return await this.ixHttp.post(path, params);
  }
}

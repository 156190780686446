/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "influxapi",
            "endpoint": "https://huf8xu4tm6.execute-api.ap-southeast-2.amazonaws.com/yifandev",
            "region": "ap-southeast-2"
        },
        {
            "name": "integration",
            "endpoint": "https://52gfvsrik6.execute-api.ap-southeast-2.amazonaws.com/yifandev",
            "region": "ap-southeast-2"
        },
        {
            "name": "webhook",
            "endpoint": "https://m6hgjlfsnl.execute-api.ap-southeast-2.amazonaws.com/yifandev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:48029662-c5b1-4cd1-b9ec-12db1401cf46",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_3edNHC4FM",
    "aws_user_pools_web_client_id": "2u4v69gipn9ktj6h00f79m5r1p",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "commissioningCompletedMessage-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "controllableLoadConsumption-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discoreco-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discorecoTasks-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "gmexport-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "group-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "groupusers-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrol-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrolhistory-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterDataExportHistory-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterEvents-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterStatusRefreshHistory-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterstatus-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "onsitemetercomm-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "org-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "policy-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "provisioningCompletedMessage-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "service-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tasks-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "touLatestReportMessage-yifandev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "user-yifandev",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;

import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { UserService } from '../services/user/user.service';
import { GetUser, GetOrgUser, UpdateOrgUser, AddOrgUser } from '../actions/user.action';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { UpdateAuthorizatoinToken } from '../actions/authorization.action';
import { PolicyService } from '../services/policy/policy.service';
import { OrganizationState, OrganizationStateModel } from './organization.state';
import { IxHttp } from 'src/app/utils/ix-http';

var base64 = require('base-64');

const defaultUser = <User>{
    id: undefined,
    email: undefined,
    policy: []
};

export class UserStateModel {
    user: User;
    orgUsers: {
        [orgName: string]: User[]
    };
}

@Injectable()
@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: defaultUser,
        orgUsers: {}
    }
})
export class UserState {

    constructor(private userService: UserService,
        private policyService: PolicyService,
        private ixHttp: IxHttp
    ) {
    }

    // Current user's detail
    @Selector()
    static user(state: UserStateModel) {
        return state.user;
    }

    static getItem = (org) => {
        let sc = []
        if (org.org_name !== 'system')
            sc = org.privileges.map(p => {
                return {
                    service: p.service,
                    url: org.org_name === 'system' ? `/portal/organisation/${org.org_name}/admin` :
                        `/portal/organisation/${org.org_name}/${p.service}`,
                    org: org.org_name
                }
            })
        return {
            title: org.org_name,
            url: org.org_name === 'system' ? '/portal/system' : '/portal/organisation/' + org.org_name,
            icon: 'business',
            services: sc
        }
    }

    // Current user's detail
    @Selector()
    static userPolicy(state: UserStateModel) {
        const user = state.user;
        const orgs = user.policy.map(p => p.org_name);
        const res = []
        for (var org of user.policy) {
            if (org.org_name === 'system') {
                res.push(this.getItem(org));
                break
            }
        }
        for (var org of user.policy) {
            if (org.org_name !== 'system') {
                res.push(this.getItem(org));
            }
        }
        // console.log(res)
        return res
    }

    // Whether login user is a sysadmin of current organisation
    @Selector([OrganizationState])
    static isSysadmin(state: UserStateModel, organizationState: OrganizationStateModel) {
        const result = state.user.policy.filter(p => p.org_name == organizationState.currentOrg)
            .flatMap(p => p.privileges)
            .filter(p => p.service == "admin")
            .flatMap(s => s.actions)
            .filter(a => a.name == "sysadmin")
        return result.length > 0;
    }

    // All the users belong to an organisation
    static orgUsers(org: string) {
        return createSelector([UserState], (state: UserStateModel) => {
            return state.orgUsers[org];
        });
    }

    // Get services user have access to on the organisation
    static services(org: string) {
        return createSelector([UserState], (state: UserStateModel) => {
            const services = state.user.policy.filter(p => p.org_name == org)
                .flatMap(p => p.privileges)
                .map(s => s.service)
            return services.sort();
        });
    }

    @Action(GetUser)
    async getUser(ctx: StateContext<UserStateModel>) {
        // const user = await IxHttp.instance().get('/user');
        const user = await this.ixHttp.get('/user');
        // const user = await this.userService.getUser();
        // console.log(user)

        const policy = JSON.parse(base64.decode(user.policy.split('.')[0]));

        await ctx.dispatch(new UpdateAuthorizatoinToken(user.policy)).toPromise();
        user.policy = policy;
        ctx.patchState({
            user: user
        });
    }
    // new way to do the action
    // @Action(UserAction)
    // add({ getState, setState }: StateContext<UserStateModel>, { payload }: UserAction) {
    //   const state = getState();
    //   setState({ ...state, items: [...state.items, payload] });
    // }

    @Action(GetOrgUser)
    async getOrgUser(ctx: StateContext<UserStateModel>, action: GetOrgUser) {
        let orgUsers = { ...ctx.getState().orgUsers };

        const users = await this.userService.getOrgUsers(action.orgName);
        orgUsers[action.orgName] = users;
        ctx.patchState({
            orgUsers: orgUsers
        })
    }

    @Action(UpdateOrgUser)
    async updateOrgUser(ctx: StateContext<UserStateModel>, action: UpdateOrgUser) {
        await this.policyService.updateOrgUser(action.payload);
    }

    @Action(AddOrgUser)
    async addOrgUser(ctx: StateContext<UserStateModel>, action: AddOrgUser) {
        await this.policyService.addOrgUser(action.payload);
    }
}
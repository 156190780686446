
export class UpdateProgressCount {
    static readonly type = '[User State] Update Progress Count';

    constructor(public count: number) {

    }
}
export class UpdateProgressCountBulk {
    static readonly type = '[User State] Update Progress Count Bulk';

    constructor(public bulk_discoreco_count: number) {

    }
}

export class ProgressCountIncrease {
    static readonly type = '[Progress State] Update Progress Coun ProgressCountIncreaset';
}

export class ProgressCountReduce {
    static readonly type = '[Progress State] Update Progress Count ProgressCountReduce';
}


export class GetUser {
    static readonly type = '[User API] Get user';
}

export class GetOrgUser {
    static readonly type = '[User API] Get users in organisation';

    constructor(public orgName: string) {}
}

export class UpdateOrgUser {
    static readonly type = '[Policy API] Update user policy';

    constructor(public payload: object) {}
}

export class AddOrgUser {
    static readonly type = '[Policy API] Add user to organisation';

    constructor(public payload: object) {}
}
import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector, Select, createSelector } from '@ngxs/store';
import { UpdateProgressCount, UpdateProgressCountBulk } from '../actions/progress.action';



export class ProgressStateModel {
    count: number;
    bulk_discoreco_count: number
}

@Injectable()
@State<ProgressStateModel>({
    name: 'progress',
    defaults: {
        count: 0,
        bulk_discoreco_count:0
    }
})
@Injectable() // don't know why
export class ProgressState {
    constructor() { }

    @Selector()
    static count(state: ProgressStateModel) {
        return state.count
    }

    @Selector()
    static bulk_discoreco_count(state: ProgressStateModel) {
        return state.bulk_discoreco_count
    }

    @Action(UpdateProgressCount)
    updateProgress(ctx: StateContext<ProgressStateModel>, action: UpdateProgressCount) {
        ctx.patchState({
            count: action.count
        })
    }

    @Action(UpdateProgressCountBulk)
    updateProgressBulk(ctx: StateContext<ProgressStateModel>, action: UpdateProgressCountBulk) {
        ctx.patchState({
            bulk_discoreco_count: action.bulk_discoreco_count
        })
    }

    // @Action(UpdateProgressCount)
    // updateProgressIncrease(ctx: StateContext<ProgressStateModel>) {
    //     ctx.patchState({
    //         count: ctx.getState().count++
    //     })
    // }

    // @Action(UpdateProgressCount)
    // updateProgressReduce(ctx: StateContext<ProgressStateModel>) {
    //     ctx.patchState({
    //         count: ctx.getState().count--
    //     })
    // }
}
import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector, Select, createSelector} from '@ngxs/store';
import { UpdateAuthorizatoinToken } from '../actions/authorization.action';

var base64 = require('base-64');

export class AuthorizationStateModel {
    token: string;
}

@Injectable()
@State<AuthorizationStateModel>({
    name: 'authorization',
    defaults: {
        token: undefined
    }
})
export class AuthorizationState {
    constructor() {}

    @Selector()
    static token(state: AuthorizationStateModel) {
        return state.token
    }

    @Action(UpdateAuthorizatoinToken)
    updateToken(ctx: StateContext<AuthorizationStateModel>, action: UpdateAuthorizatoinToken) {
        ctx.patchState({
            token: action.policy
        })
    }
}
import { Injectable } from '@angular/core';
import { IxHttp } from 'src/app/utils/ix-http';
import { ApiService } from '../api/api.service';

// const API_NAME = "influxapi";

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends ApiService {
  constructor(private ixHttp: IxHttp) { 
    super()
  }

  public ServiceIdToNameMapping = {
    loadcontrol: 'Demand Management for Hot Water',
    discoreco: 'Disconnect/Reconnect',
    admin: 'Administration',
    earegistry: 'EA Registry',
    gmexport: 'GoodMeasure Export',
    systeminfo: 'Cloud Managment',
    meter: 'Meter Status',
    onsitemetercomm: 'Meter Upgrade Utility', 
    realtimestreaming: 'Real-Time Streaming'
  }

  async getAllServices() {
    const init = this.getAPIInit();
    // return await API.get(API_NAME, "/service", init);
    return await this.ixHttp.get("/service");
  }
}

export class GetOrganization {
    static readonly type = '[Organisation API] Get organisation';

    constructor(public name: string) {}
}

export class GetAllOrganizations {
    static readonly type = '[Organisation API] Get all organizations';

    // constructor() {}
}

export class SetCurrentOrganization {
    static readonly type = '[Organisation Component] Set Current Organisation'

    constructor(public name: string) {}
}
export class SetCurrentOrganizationService {
    static readonly type = '[Organisation Component] Set Current Organisation Service'

    constructor(public service: string) {}
}

export class CreateOrganization {
    static readonly type = '[Organisation API] Create organisation';

    constructor(public payload: object) {}
}

export class UpdateOrganization {
    static readonly type = '[Organisation API] Update organisation';

    constructor(public payload: object) {}
}

export class CreateGroup {
    static readonly type = '[Group API] Create Group';

    constructor(public orgName: string, 
                public groupName: string,
                public policy: object) {}
}

export class UpdateGroup {
    static readonly type = '[Group API] Update Group';

    constructor(public orgName: string, 
                public groupName: string,
                public policy: object) {}
}

export class DeleteGroup {
    static readonly type = '[Group API] Delete Group';

    constructor(public orgName: string, 
                public groupName: string) {}
}

export class GetOrgGroups {
    static readonly type = '[Group API] Get all group of an organisation';

    constructor(public orgName: string) {}
}

export class AddGroupMembers {
    static readonly type = '[Group API] Add users to group';

    constructor(public orgName: string, 
                public groupName: string,
                public users: Array<string>) {}
}

export class RemoveGroupMembers {
    static readonly type = '[Group API] Delete user from group';

    constructor(public orgName: string, 
                public groupName: string,
                public users: Array<string>) {}
}

export class GetGroupMembers {
    static readonly type = '[Group API] Get users of group';

    constructor(public orgName: string, 
                public groupName: string) {}
}

import { Injectable } from '@angular/core';
import { AuthorizationState } from '../../states/authorization.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  @Select(AuthorizationState.token) token: Observable<any>;
  init = {};

  constructor() { 
    this.token.subscribe(token => {
      this.init = {
          headers: {
              // "Policy": token
          }
      }
    });
  }

  getAPIInit() {
    let init = JSON.parse(JSON.stringify(this.init));
    // let session = await Auth.currentSession();
    // init.headers['Authorization'] = `Bearer ${session.getAccessToken().getJwtToken()}`;
    return init;
  }
}

import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { ApiService } from '../api/api.service';
import { IxHttp } from 'src/app/utils/ix-http';

// const API_NAME = "influxapi";

/**
 * OrganizationService loads user profile and construct a list of organizations
 * a user has privileges for. 
 */
@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends ApiService {

  constructor(
    private userService: UserService,
    private ixHttp: IxHttp
  ) {
    super()
  }

  async getAllOrganizations() {
    const init = this.getAPIInit();
    // return await API.get(API_NAME, "/org/all", init)
    return await this.ixHttp.get(`/org/all`)
  }

  async createOrganization(payload) {
    let init = this.getAPIInit();
    init["body"] = payload
    const params = { 'body': payload }
    // return await API.post(API_NAME, "/org", init);
    return await this.ixHttp.post(`/org`, params)
  }

  async updateOrganization(payload) {
    let init = this.getAPIInit();
    init["body"] = payload
    const params = { 'body': payload }
    // return await API.put(API_NAME, "/org", init);
    return await this.ixHttp.put(`/org`, params)
  }

  async getOrganization(orgName) {
    const init = this.getAPIInit();
    // let orgDetails = await API.get(API_NAME, "/org/" + orgName, init);
    let orgDetails = await this.ixHttp.get("/org/" + orgName);
    let groups = await this.getGroups(orgName);
    orgDetails.groups = groups.data;
    for (let g of orgDetails.groups) {
      if (!g['name']) continue // skip empty group name by Jack
      let groupUsers = await this.getGroupMembers(orgName, g['name']);
      g.users = groupUsers.data;
    }

    return orgDetails;
  }

  async createGroup(orgName: string, groupName: string, policy: object) {
    let init = this.getAPIInit();
    init['body'] = { name: groupName, policy: policy };
    const params = { 'body': { name: groupName, policy: policy } }
    // return await API.post(API_NAME, `/org/${orgName}/group`, init);
    return await this.ixHttp.post(`/org/${orgName}/group`, params);
  }

  async updateGroup(orgName: string, groupName: string, policy: object) {
    let init = this.getAPIInit();
    init['body'] = { policy: policy };
    const params = { 'body': { policy: policy } }
    // return await API.put(API_NAME, `/org/${orgName}/group/${groupName}`, init);
    return await this.ixHttp.put(`/org/${orgName}/group/${groupName}`, params);
  }

  async removeGroup(orgName: string, groupName: string) {
    const init = this.getAPIInit();
    // return await API.del(API_NAME, `/org/${orgName}/group/${groupName}`, init);
    return await this.ixHttp.delete(`/org/${orgName}/group/${groupName}`);
  }

  async getGroups(orgName) {
    const init = this.getAPIInit();
    // return await API.get(API_NAME, `/org/${orgName}/group`, init);
    return await this.ixHttp.get(`/org/${orgName}/group`);
  }

  async getGroupMembers(orgName: string, groupName: string) {
    const init = this.getAPIInit();
    // return await API.get(API_NAME, `/org/${orgName}/group/${groupName}/user`, init);
    return await this.ixHttp.get(`/org/${orgName}/group/${groupName}/user`);
  }

  async removeGroupMember(orgName: string, groupName: string, users: Array<string>) {
    let init = this.getAPIInit();
    init['body'] = { users };
    const params = { 'body': { users } }
    // return await API.del(API_NAME, `/org/${orgName}/group/${groupName}/user`, init);
    return await this.ixHttp.delete(`/org/${orgName}/group/${groupName}/user`, params);
  }

  async addGroupMembers(orgName: string, groupName: string, users: Array<string>) {
    let init = this.getAPIInit();
    init['body'] = { users };
    const params = { 'body': { users } }
    // return await API.put(API_NAME, `/org/${orgName}/group/${groupName}/user`, init);
    return await this.ixHttp.put(`/org/${orgName}/group/${groupName}/user`, params);
  }
}

import { Injectable } from "@angular/core";
import {State, Action, StateContext, Selector, Select} from '@ngxs/store';
import { ServiceService } from '../services/service/service.service';
import { GetServices } from '../actions/service.action';
import { Service } from '../models/service';

export class ServiceStateModel {
    services: Array<Service>;
}

@Injectable()
@State<ServiceStateModel>({
    name: 'service',
    defaults: {
        services: []
    }
})

export class ServiceState {
    constructor(private serviceService: ServiceService) {
    }

    @Selector()
    static services(state: ServiceStateModel) {
        return state.services;
    }

    @Action(GetServices)
    async getServices(ctx: StateContext<ServiceStateModel>) {
        const services = await this.serviceService.getAllServices();
        ctx.patchState({
            services: services
        })
    }
}